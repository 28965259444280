import React, { useContext } from "react"

// Contexts
import SearchContext from "../../../context/searchContext"

const SectionResult = () => {
  const { handleChangeOrder, resultSearchModal } = useContext(SearchContext)
  const { data } = resultSearchModal
  const { orderBy, orderType, typeShippingCompany } = data

  return (
    <div className="table-dataShippingCompanieschpa ver1">
  {data.rowShippingCompany && data.rowShippingCompany.length > 0 ? (
    <>
      <div className="table-chpa-firstcol">
        <table>
          <thead>
            <tr>
              <th
                style={{
                  color: "white",
                  fontWeight: "bold",
                  height: "5.5rem",
                }}
                className="column-chpa-one"
              >
                Shipping Line
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="column-chpa column-chpa-one result-order icon-chpa">
                <span
                  className="result-order-desc"
                  tabIndex={0}
                  role="button"
                  onKeyPress={() => {}}
                  onClick={() => handleChangeOrder("day", "desc", typeShippingCompany)}
                  style={{
                    color: orderBy === "desc" && orderType === "day" ? "#fff" : "#ccc",
                  }}
                >
                  <i className="fas fa-arrow-left"></i>
                </span>
                <span className="result-order-title">Transit time</span>
                <span
                  className="result-order-asc"
                  tabIndex={0}
                  role="button"
                  onKeyPress={() => {}}
                  onClick={() => handleChangeOrder("day", "asc", typeShippingCompany)}
                  style={{
                    color: orderBy === "asc" && orderType === "day" ? "#fff" : "#ccc",
                  }}
                >
                  <i className="fas fa-arrow-right"></i>
                </span>
              </td>
            </tr>
            <tr>
              <td className="column-chpa-one">Price</td>
            </tr>
            <tr>
              <td className="column-chpa-one">Vessel</td>
            </tr>
            <tr>
              <td className="column-chpa-one result-order icon-chpa">
                <span
                  className="result-order-desc"
                  tabIndex={0}
                  role="button"
                  onKeyPress={() => {}}
                  onClick={() => handleChangeOrder("date", "desc", typeShippingCompany)}
                  style={{
                    color: orderBy === "desc" && orderType === "date" ? "#fff" : "#ccc",
                  }}
                >
                  <i className="fas fa-arrow-left"></i>
                </span>
                <span className="result-order-title">Vessel Departure Date</span>
                <span
                  className="result-order-asc"
                  tabIndex={0}
                  role="button"
                  onKeyPress={() => {}}
                  onClick={() => handleChangeOrder("date", "asc", typeShippingCompany)}
                  style={{
                    color: orderBy === "asc" && orderType === "date" ? "#fff" : "#ccc",
                  }}
                >
                  <i className="fas fa-arrow-right"></i>
                </span>
              </td>
            </tr>
            <tr>
              <td className="column-chpa-one">Direct</td>
            </tr>
            <tr>
              <td className="column-chpa-one">One Transshipment</td>
            </tr>
            <tr>
              <td className="column-chpa-one">Two Transshipments</td>
            </tr>
            <tr className="result-name-port">
              <td className="column-chpa-one result-order icon-chpa">
                <span
                  className="result-order-desc"
                  tabIndex={0}
                  role="button"
                  onKeyPress={() => {}}
                  onClick={() => handleChangeOrder("loadingPort", "desc", typeShippingCompany)}
                  style={{
                    color: orderBy === "desc" && orderType === "loadingPort" ? "#fff" : "#ccc",
                  }}
                >
                  <i className="fas fa-arrow-left"></i>
                </span>
                <span className="result-order-title">Loading Port</span>
                <span
                  className="result-order-asc"
                  tabIndex={0}
                  role="button"
                  onKeyPress={() => {}}
                  onClick={() => handleChangeOrder("loadingPort", "asc", typeShippingCompany)}
                  style={{
                    color: orderBy === "asc" && orderType === "loadingPort" ? "#fff" : "#ccc",
                  }}
                >
                  <i className="fas fa-arrow-right"></i>
                </span>
              </td>
            </tr>
            <tr className="result-name-port">
              <td className="column-chpa-one result-order icon-chpa">
                <span
                  className="result-order-desc"
                  tabIndex={0}
                  role="button"
                  onKeyPress={() => {}}
                  onClick={() => handleChangeOrder("dischargePort", "desc", typeShippingCompany)}
                  style={{
                    color: orderBy === "desc" && orderType === "dischargePort" ? "#fff" : "#ccc",
                  }}
                >
                  <i className="fas fa-arrow-left"></i>
                </span>
                <span className="result-order-title">Discharge Port</span>
                <span
                  className="result-order-asc"
                  tabIndex={0}
                  role="button"
                  onKeyPress={() => {}}
                  onClick={() => handleChangeOrder("dischargePort", "asc", typeShippingCompany)}
                  style={{
                    color: orderBy === "asc" && orderType === "dischargePort" ? "#fff" : "#ccc",
                  }}
                >
                  <i className="fas fa-arrow-right"></i>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="table-chpa-nextcols js-pscroll">
        <div className="table100-nextcols">
          <table>
            <thead>
              <tr>
                {data.rowShippingCompany.map((shippingCompany, index) => (
                  <th
                    className="column-chpa-two"
                    style={{ height: "5.5rem" }}
                    key={index}
                  >
                    <a className="shipping-company" href={shippingCompany.url} target="_blank" rel="noreferrer">
                      {shippingCompany.name}
                    </a>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {data.rowDaysString.map((dayString, index) => (
                  <td className="column-chpa column-chpa-two" key={index}>
                    {dayString}
                  </td>
                ))}
              </tr>

              <tr>
                {data.rowVessel.map((vessel, index) => (
                  <td className="column-chpa-two icon-chpa price-column" key={index}>
                    Coming soon
                  </td>
                ))}
              </tr>

              <tr>
                {data.rowVessel.map((vessel, index) => (
                  <td className="column-chpa-two icon-chpa" key={index}>
                    <a href={`https://www.vesselfinder.com/vessels?name=${encodeURI(vessel)}`} target="_blank" rel="noreferrer">
                      {vessel}
                    </a>
                  </td>
                ))}
              </tr>

              <tr>
                {data.rowVesselDepertureDate.map((vesselDepertureDate, index) => (
                  <td className="column-chpa-two icon-chpa" key={index}>
                    &#xf133; {vesselDepertureDate}
                  </td>
                ))}
              </tr>

              <tr>
                {data.rowDirect.map((direct, index) => (
                  <td className="column-chpa-two" key={index}>
                    {direct ? <i className="fa fa-check"></i> : ""}
                  </td>
                ))}
              </tr>

              <tr>
                {data.rowOneTransshipment.map((oneTransshipment, index) => (
                  <td className="column-chpa-two" key={index}>
                    {oneTransshipment ? <i className="fa fa-check"></i> : ""}
                  </td>
                ))}
              </tr>

              <tr>
                {data.rowTwoTransshipment.map((twoTransshipment, index) => (
                  <td className="column-chpa-two" key={index}>
                    {twoTransshipment ? <i className="fa fa-check"></i> : ""}
                  </td>
                ))}
              </tr>

              <tr className="result-port">
                {data.rowLoadingPorts.map((loadingPort, index) => (
                  <td className="column-chpa-two icon-chpa" key={index}>
                    {loadingPort}
                  </td>
                ))}
              </tr>

              <tr className="result-port">
                {data.rowDischargePorts.map((dischargePort, index) => (
                  <td className="column-chpa-two icon-chpa" key={index}>
                    {dischargePort}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  ) : (
    <p style={{
          textAlign: "center",
          fontSize: "1.5rem",
          color: "#888",
          marginTop: "2rem"
        }}>
          No results found
    </p>  )}
</div>

  )
}

export default SectionResult
