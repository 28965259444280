import React, { useRef, useState, useContext } from "react"
import { useForm, Controller } from "react-hook-form"
import ReCAPTCHA from "react-google-recaptcha"
import { Typeahead, Highlighter } from "react-bootstrap-typeahead"
import classNames from "classnames"
import { yupResolver } from "@hookform/resolvers/yup"
import { addWeeks } from "date-fns"
import DatePicker from "react-datepicker"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

// Contexts
import SearchContext from "../../../context/searchContext"

// Components
import Modal from "../../Modal"

// Constas, helpers, validations and services
import { extractSearchData } from "../../../helpers/advancedSearch"
import getAdvancedSearch from "../../../services/getAdvancedSearch"
import { commodities, optionContainer, messageError } from "../../../constants"
import { formatDataShippingCompanies } from "../../../helpers/functionSearch"
import { schemaAdvanceSearch, viewErrorAdvancedSearch } from "../../../validations"
import { getLocations, getLocationsHead } from "../../../services/getLocations";
import { orderDataFetch, orderData } from "../../../helpers/orderData";
import { Cookies } from "react-cookie";

const AdvanceSearch = () => {
  const recaptchaRef = useRef()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const cookies = new Cookies();

  const { setResultSearchModal, setShowAdvancedModal, isShowadvancedModal, setNoResultSearchModal } = useContext(SearchContext)
  const { ERROR_EMPTY_SEARCH, ERROR_TYPE_CONTAINER_SEARCH } = messageError

  const [stateForm, setStateForm] = useState({
    isLoading: false,
    isApplyValidate: false,
  })
  const [options1, setOptions1] = useState([])
  const [options2, setOptions2] = useState([])
  const [options3, setOptions3] = useState([])
  const [op1, setOp1] = useState([])
  const [op2, setOp2] = useState([])

  const { isLoading, isApplyValidate } = stateForm

  const { register, handleSubmit, control, errors, setValue, getValues, clearErrors, watch } = useForm({
    mode: "onChange",
    resolver: yupResolver(schemaAdvanceSearch),
    shouldFocusError: false,
  })

  const showNoResultSearchModal = (message) => {
    setShowAdvancedModal(false)

    setTimeout(() => {
      setNoResultSearchModal({
        isShow: true,
        isAdvancedSearch: true,
        message,
      })
    }, 400)

    recaptchaRef.current.reset()
    setStateForm((oldData) => ({
      ...oldData,
      isLoading: false,
    }))
  }

  const onSubmit = async (searchData) => {
    const { containerSize, containerType } = searchData
    if (["REEF Standard", "REEF High"].includes(containerType) && ["20", "40", "45"].includes(containerSize)) {
      showNoResultSearchModal(ERROR_TYPE_CONTAINER_SEARCH)
      return false
    }

    const token = await recaptchaRef.current.executeAsync()

    setStateForm({
      isLoading: true,
      isApplyValidate: false,
    })
    const locations = orderDataFetch({originPick: searchData.locationOne, destinationPick: searchData.locationTwo, originData: op1, destinationData: op2})

    searchData.locations = JSON.stringify(locations)

    const res = await getAdvancedSearch({
      ...extractSearchData(searchData),
      token,
    })

    const { search, result, success, message } = res

    // Error Fatal
    if (success === false) {
      showNoResultSearchModal(message)
      return false
    }

    const shippingCompanies = formatDataShippingCompanies({
      orderType: "date",
      orderBy: "desc",
      typeShippingCompany: "ALL",
      dataShippingCompanies: result,
      dataSearch: search,
    })

    // Empty Search
    if (shippingCompanies !== null) {
      showNoResultSearchModal(ERROR_EMPTY_SEARCH)
      return false
    }

    setShowAdvancedModal(false)
    setTimeout(() => {
      setResultSearchModal((oldData) => ({
        ...oldData,
        isShow: true,
        isAdvancedSearch: true,
        data: {
          originData: res.result,
          dataSearch: search,
          orderType: "date",
          orderBy: "desc",
          typeShippingCompany: "ALL",
          ...shippingCompanies,
        },
      }))
    }, 400)

    recaptchaRef.current.reset()
    setStateForm((oldData) => ({
      ...oldData,
      isLoading: false,
    }))
  }

  const onError = (errors) => {
    setStateForm((oldData) => ({
      ...oldData,
      isApplyValidate: true,
    }))

    viewErrorAdvancedSearch(errors)
  }

  const handleCloseModal = (type) => {
    if (type === "close") {
      setShowAdvancedModal(false)

      setResultSearchModal((oldData) => ({
        ...oldData,
        isAdvancedSearch: false,
      }))
    }
  }

  const locationsOrigin = async (text) => {
    if (text.length > 3) {
      const data = await locations({ text });
      const order = orderData({data});
      setOp1(data);
      setOptions1(order);
    } else {
      setOp1([]);
      setOptions1([]);
    }
  };

  const locationsDestination = async (text) => {
    if (text.length > 3) {
      const data = await locations({ text });
      const order = orderData({data});
      setOp2(data);
      setOptions2(order);
    } else {
      setOp2([]);
      setOptions2([]);
    }
  };

  const locationsContact = async (text) => {
    if (text.length > 3) {
      const data = await locations({ text });
      const order = orderData({data});
      setOptions3(order);
    } else {
      setOptions3([]);
    }
  };

  const timeToken = () => {
    const expirationDate = new Date()
    expirationDate.setTime(expirationDate.getTime() + (1 * 60 * 60 * 1000))

    return expirationDate
  }

  const headVerifyToken = async () => {
    const hash = cookies.get('hash-locations-chalupa');
    if(!hash) {
      const token = await executeRecaptcha('focusInput')
      const hash = await getLocationsHead(token)
      
      cookies.set('hash-locations-chalupa', hash.hash)
    }
  }

  const locations = async ({text}) => {
    const hash = cookies.get('hash-locations-chalupa')
    if(!hash) {
      await headVerifyToken()
    }

    if(hash) {
      const { success, ...data } = await getLocations({ cityName: text, token: hash })
      return await data;
    } 

    return {}
  }

  const handleClickHead = () => {
    const hash = cookies.get('hash-locations-chalupa')
    const token = cookies.get('locations-chalupa')
    if(!hash || hash === 'undefined' || !token) {
      headVerifyToken()
    }
  }

  return (
    <Modal
      idModal="advanced-search-modal"
      classModal="bd-modal-chpa"
      classTypeModal="modal-xl"
      ariaLabelledby="advanced-search"
      isShow={isShowadvancedModal}
      onButtonClick={handleCloseModal}
    >
      <form onSubmit={handleSubmit(onSubmit, onError)} className="needs-validation" noValidate>
        <div className="modal-content">
          <div className="modal-header-chpa">
            <h5 className="modal-title-chpa">ADVANCED SEARCH</h5>
            <button
              type="button"
              className="close-chpa"
              aria-label="Close"
              onClick={() => {
                handleCloseModal("close")
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h6 className="modal-title-chpa">*Please fill in the required fields.</h6>
            <br />
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="accordion" id="accordionExample">
                    {/* Origin Location */}
                    <div className="card-chpa">
                      <h6 className="card-header-chpa" id="origin-chpa">
                        <a
                          data-toggle="collapse"
                          data-target="#collapseOrigin"
                          aria-expanded="true"
                          aria-controls="collapseOrigin"
                          href="#collapseOrigin"
                          className="collapsed a-chpa a-chpa-without-visited"
                        >
                          <i className="fa fa-chevron-down pull-right"></i>
                          ORIGIN LOCATION
                        </a>
                      </h6>
                      <div id="collapseOrigin" className="collapse show" aria-labelledby="origin-chpa" data-parent="#accordionExample">
                        <div className="card-body">
                          <div className="container">
                            <div className="form-row">
                              <div className="col-lg-4 col-md-12 col-12 form-group form-chpa-one" onClick={handleClickHead}>
                                <div className="form-chpa-two">
                                  <label htmlFor="locationOne">
                                    Location
                                    <span className="fa fa-asterisk span-chpa"></span>
                                  </label>

                                  <Controller
                                    control={control}
                                    name="locationOne"
                                    defaultValue=""
                                    render={({ onChange }) => (
                                      <Typeahead
                                        id="locationOne"
                                        options={options1}
                                        onInputChange={(text) => locationsOrigin(text || "")}                                        
                                        placeholder="&#xf041;"
                                        inputProps={{
                                          className: classNames(
                                            "form-control",
                                            "icon-chpa",
                                            "typecountry",
                                            "tt-query",
                                            errors.locationOne ? "is-invalid" : isApplyValidate ? "is-valid" : ""
                                          ),
                                          spellCheck: "false",
                                        }}
                                        renderMenuItemChildren={(option, props) => (
                                          <span className="rbt-text">
                                            <Highlighter search={props.text}>{option}</Highlighter>
                                          </span>
                                        )}
                                        onChange={(value) => {
                                          onChange(value[0])
                                        }}
                                        onBlur={(e) => {
                                          onChange(e.target.value)
                                        }}
                                      />
                                    )}
                                  />
                                  <small id="locationHelpOne" className="form-text small-chpa">
                                    Enter a city, state, or country.
                                  </small>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-6 form-group form-chpa-one">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    name="cyCheckOne"
                                    className={classNames("custom-control-input", errors.cyCheckOne ? "is-invalid" : "")}
                                    ref={register}
                                  />
                                  <div
                                    className="custom-control-label"
                                    style={{ fontWeight: "700" }}
                                    tabIndex={0}
                                    htmlFor="cyCheckOne"
                                    role="button"
                                    onClick={() => {
                                      setValue("cyCheckOne", !getValues("cyCheckOne"))
                                      setValue("sdCheckOne", false)
                                      clearErrors(["sdCheckOne", "cyCheckOne"])
                                    }}
                                    onKeyDown={() => {}}
                                  >
                                    Merchant Haulage (CY)
                                  </div>
                                  <div className="invalid-feedback invalid-chpa">Select one of the options</div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-6 form-group form-chpa-one">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    name="sdCheckOne"
                                    className={classNames("custom-control-input", errors.sdCheckOne ? "is-invalid" : "")}
                                    ref={register}
                                  />
                                  <div
                                    className="custom-control-label"
                                    style={{ fontWeight: "700" }}
                                    tabIndex={0}
                                    htmlFor="sdCheckOne"
                                    role="button"
                                    onClick={() => {
                                      setValue("sdCheckOne", !getValues("sdCheckOne"))
                                      setValue("cyCheckOne", false)
                                      clearErrors(["sdCheckOne", "cyCheckOne"])
                                    }}
                                    onKeyDown={() => {}}
                                  >
                                    Carrier Haulage (SD)
                                  </div>
                                  <div className="invalid-feedback invalid-chpa">Select one of the options</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Destination Location */}
                    <div className="card-chpa">
                      <h6 className="card-header-chpa" id="destination-chpa">
                        <a
                          data-toggle="collapse"
                          data-target="#collapseDestination"
                          aria-expanded="true"
                          aria-controls="collapseDestination"
                          href="#collapseDestination"
                          className="collapsed a-chpa a-chpa-without-visited"
                        >
                          <i className="fa fa-chevron-down pull-right"></i>
                          DESTINATION LOCATION
                        </a>
                      </h6>
                      <div id="collapseDestination" className="collapse" aria-labelledby="destination-chpa" data-parent="#accordionExample">
                        <div className="card-body">
                          <div className="container">
                            <div className="form-row">
                              <div className="col-lg-4 col-md-12 col-12 form-group form-chpa-one" onClick={handleClickHead}>
                                <div className="form-chpa-two">
                                  <label htmlFor="locationOne">
                                    Location
                                    <span className="fa fa-asterisk span-chpa"></span>
                                  </label>

                                  <Controller
                                    control={control}
                                    name="locationTwo"
                                    defaultValue=""
                                    render={({ onChange }) => (
                                      <Typeahead
                                        id="locationTwo"
                                        options={options2}
                                        onInputChange={(text) => locationsDestination(text || "")} 
                                        placeholder="&#xf041;"
                                        inputProps={{
                                          className: classNames(
                                            "form-control",
                                            "icon-chpa",
                                            "typecountry",
                                            "tt-query",
                                            errors.locationTwo ? "is-invalid" : isApplyValidate ? "is-valid" : ""
                                          ),
                                          spellCheck: "false",
                                        }}
                                        renderMenuItemChildren={(option, props) => (
                                          <span className="rbt-text">
                                            <Highlighter search={props.text}>{option}</Highlighter>
                                          </span>
                                        )}
                                        onChange={(value) => {
                                          onChange(value[0])
                                        }}
                                        onBlur={(e) => {
                                          onChange(e.target.value)
                                        }}
                                      />
                                    )}
                                  />
                                  <small id="locationHelpOne" className="form-text small-chpa">
                                    Enter a city, state, or country.
                                  </small>
                                </div>
                              </div>

                              <div className="col-lg-4 col-md-6 col-6 form-group form-chpa-one">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    name="cyCheckTwo"
                                    className={classNames("custom-control-input", errors.cyCheckTwo ? "is-invalid" : "")}
                                    ref={register}
                                  />
                                  <div
                                    className="custom-control-label"
                                    style={{ fontWeight: "700" }}
                                    tabIndex={0}
                                    htmlFor="cyCheckTwo"
                                    role="button"
                                    onClick={() => {
                                      setValue("cyCheckTwo", !getValues("cyCheckTwo"))
                                      setValue("sdCheckTwo", false)
                                      clearErrors(["sdCheckTwo", "cyCheckTwo"])
                                    }}
                                    onKeyDown={() => {}}
                                  >
                                    Merchant Haulage (CY)
                                  </div>
                                  <div className="invalid-feedback invalid-chpa">Select one of the options</div>
                                </div>
                              </div>

                              <div className="col-lg-4 col-md-6 col-6 form-group form-chpa-one">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    name="sdCheckTwo"
                                    className={classNames("custom-control-input", errors.sdCheckTwo ? "is-invalid" : "")}
                                    ref={register}
                                  />
                                  <div
                                    className="custom-control-label"
                                    style={{ fontWeight: "700" }}
                                    tabIndex={0}
                                    htmlFor="sdCheckTwo"
                                    role="button"
                                    onClick={() => {
                                      setValue("sdCheckTwo", !getValues("sdCheckTwo"))
                                      setValue("cyCheckTwo", false)
                                      clearErrors(["sdCheckTwo", "cyCheckTwo"])
                                    }}
                                    onKeyDown={() => {}}
                                  >
                                    Carrier Haulage (SD)
                                  </div>
                                  <div className="invalid-feedback invalid-chpa">Select one of the options</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Container Details */}
                    <div className="card-chpa">
                      <h6 className="card-header-chpa" id="containerDetails-chpa">
                        <a
                          data-toggle="collapse"
                          data-target="#collapseContainerDetails"
                          aria-expanded="true"
                          aria-controls="collapseContainerDetails"
                          href="#collapseContainerDetails"
                          className="collapsed a-chpa a-chpa-without-visited"
                        >
                          <i className="fa fa-chevron-down pull-right"></i>
                          CONTAINER DETAILS
                        </a>
                      </h6>
                      <div id="collapseContainerDetails" className="collapse" aria-labelledby="containerDetails-chpa" data-parent="#accordionExample">
                        <div className="card-body">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-6 col-md-12 col-12 form-group form-chpa-one">
                                <div className="form-chpa-two">
                                  <label htmlFor="contaynerType">
                                    Container Type
                                    <span className="fa fa-asterisk span-chpa"></span>
                                  </label>
                                  <div className="field-icon-wrap">
                                    <select
                                      ref={register}
                                      name="containerType"
                                      className={classNames("form-control", errors.containerType ? "is-invalid" : isApplyValidate ? "is-valid" : "")}
                                    >
                                      {optionContainer.TYPE_LIST.map((item) => (
                                        <option key={item.value} value={item.value}>
                                          {item.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-12 col-12 form-group form-chpa-one">
                                <div className="form-chpa-two">
                                  <label htmlFor="contaynerSize">
                                    Container Size
                                    <span className="fa fa-asterisk span-chpa"></span>
                                  </label>
                                  <div className="field-icon-wrap">
                                    <select
                                      ref={register}
                                      name="containerSize"
                                      className={classNames("form-control", errors.containerSize ? "is-invalid" : isApplyValidate ? "is-valid" : "")}
                                    >
                                      {optionContainer.SIZE_LIST.map((item) => (
                                        <option key={item.value} value={item.value}>
                                          {item.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-12 col-12 form-group form-chpa-one" style={{ display: "none" }}>
                                <div className="form-chpa-two">
                                  <label htmlFor="containerQuantity">Container Quantity</label>
                                  <input
                                    type="number"
                                    name="containerQuantity"
                                    className={classNames(
                                      "form-control",
                                      "input-chpa",
                                      errors.containerQuantity ? "is-invalid" : isApplyValidate ? "is-valid" : ""
                                    )}
                                    defaultValue=""
                                    placeholder="Quantity"
                                    ref={register}
                                  />
                                </div>
                                <div className="form-chpa-two" style={{ display: "none" }}>
                                  <label htmlFor="containerWeight">Weight (kg - Cargo Only)</label>
                                  <input
                                    type="number"
                                    name="containerWeight"
                                    className={classNames(
                                      "form-control",
                                      "input-chpa",
                                      errors.containerWeight ? "is-invalid" : isApplyValidate ? "is-valid" : ""
                                    )}
                                    defaultValue=""
                                    placeholder="Weight"
                                    ref={register}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-12 col-12 form-group form-chpa-one" style={{ display: "none" }}>
                                <div className="custom-control custom-checkbox">
                                  <input type="checkbox" name="soc" className="custom-control-input" ref={register} />
                                  <div
                                    className="custom-control-label"
                                    style={{ fontWeight: "700" }}
                                    tabIndex={0}
                                    htmlFor="soc"
                                    role="button"
                                    onClick={() => {
                                      setValue("soc", !getValues("soc"))
                                      clearErrors(["soc"])
                                    }}
                                    onKeyDown={() => {}}
                                  >
                                    Shipper Owned Container (SOC)
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-chpa" style={{ display: "none" }}>
                      <h6 className="card-header-chpa" id="commodityDetails-chpa">
                        <a
                          data-toggle="collapse"
                          data-target="#collapseCommodityDetails"
                          aria-expanded="true"
                          aria-controls="collapseCommodityDetails"
                          href="#collapseCommodityDetails"
                          className="collapsed a-chpa a-chpa-without-visited"
                        >
                          <i className="fa fa-chevron-down pull-right"></i>
                          COMMODITY DETAILS (OPTIONAL)
                        </a>
                      </h6>
                      <div id="collapseCommodityDetails" className="collapse" aria-labelledby="commodityDetails-chpa" data-parent="#accordionExample">
                        <div className="card-body">
                          <div className="container">
                            <div className="form-row">
                              <div className="col-lg-4 col-md-12 col-12 form-group form-chpa-one">
                                <div className="form-chpa-two">
                                  <label htmlFor="locationOne">Commodity</label>

                                  <Controller
                                    control={control}
                                    name="commodity"
                                    defaultValue=""
                                    render={({ onChange }) => (
                                      <Typeahead
                                        id="commodity"
                                        options={commodities}
                                        placeholder="&#xf041;"
                                        inputProps={{
                                          className: classNames(
                                            "form-control",
                                            "icon-chpa",
                                            "typecountry",
                                            "tt-query",
                                            errors.commodity ? "is-invalid" : isApplyValidate ? "is-valid" : ""
                                          ),
                                          spellCheck: "false",
                                        }}
                                        renderMenuItemChildren={(option, props) => (
                                          <span className="rbt-text">
                                            <Highlighter search={props.text}>{option}</Highlighter>
                                          </span>
                                        )}
                                        onChange={(value) => {
                                          onChange(value[0])
                                        }}
                                        onBlur={(e) => {
                                          onChange(e.target.value)
                                        }}
                                      />
                                    )}
                                  />
                                  <small id="commodityHelp" className="form-text small-chpa">
                                    Enter an Commodity.
                                  </small>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-12 col-12 form-group form-chpa-one">
                                <div className="custom-control custom-checkbox">
                                  <input type="checkbox" ref={register} name="dangerous" className="custom-control-input" />
                                  <div
                                    style={{ fontWeight: "700" }}
                                    htmlFor="dangerous"
                                    className="custom-control-label"
                                    tabIndex={0}
                                    role="button"
                                    onClick={() => {
                                      setValue("dangerous", !getValues("dangerous"))
                                    }}
                                    onKeyDown={() => {}}
                                  >
                                    Dangerous Cargo - HAZMAT
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-12 col-12 form-group form-chpa-one">
                                <div className="form-chpa-two">
                                  {watch("dangerous") ? (
                                    <>
                                      <label htmlFor="imoNumber">IMO Number</label>
                                      <input
                                        type="number"
                                        name="imoNumber"
                                        ref={register}
                                        className={classNames("form-control", errors.imoNumber ? "is-invalid" : isApplyValidate ? "is-valid" : "")}
                                      />
                                      <small className="form-text small-chpa">Enter an IMO number</small>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* DATES */}
                    <div className="card-chpa">
                      <h6 className="card-header-chpa" id="rateValidity-chpa">
                        <a
                          data-toggle="collapse"
                          data-target="#collapseRateValidity"
                          aria-expanded="true"
                          aria-controls="collapseRateValidity"
                          href="#collapseRateValidity"
                          className="collapsed a-chpa a-chpa-without-visited"
                        >
                          <i className="fa fa-chevron-down pull-right"></i>
                          DATE
                        </a>
                      </h6>
                      <div id="collapseRateValidity" className="collapse" aria-labelledby="rateValidity-chpa" data-parent="#accordionExample">
                        <div className="card-body">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-4 col-md-12 col-12 form-group form-chpa-one">
                                <div className="form-chpa-two">
                                  <label htmlFor="searchStartDate">
                                    Search Departure Date
                                    <span className="fa fa-asterisk span-chpa"></span>
                                  </label>
                                  <Controller
                                    control={control}
                                    name="searchStartDate"
                                    defaultValue=""
                                    render={({ onChange, onBlur, value }) => (
                                      <DatePicker
                                        minDate={new Date()}
                                        maxDate={addWeeks(new Date(), 8)}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="&#xf133;"
                                        className={classNames(
                                          "form-control",
                                          "icon-chpa",
                                          errors.searchStartDate ? "is-invalid" : isApplyValidate ? "is-valid" : ""
                                        )}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        selected={value}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-chpa">
                      <h6 className="card-header-chpa" id="companyDetails-chpa">
                        <a
                          data-toggle="collapse"
                          data-target="#collapseCompanyDetails"
                          aria-expanded="true"
                          aria-controls="collapseCompanyDetails"
                          href="#collapseCompanyDetail"
                          className="collapsed a-chpa a-chpa-without-visited"
                        >
                          <i className="fa fa-chevron-down pull-right"></i>
                          COMPANY DETAILS
                        </a>
                      </h6>
                      <div id="collapseCompanyDetails" className="collapse" aria-labelledby="companyDetails-chpa" data-parent="#accordionExample">
                        <div className="card-body">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-4 col-md-12 col-12 form-group form-chpa-one">
                                <div className="form-chpa-two">
                                  <label htmlFor="companyName">
                                    Company Name
                                    <span className="fa fa-asterisk span-chpa"></span>
                                  </label>
                                  <input
                                    type="text"
                                    name="companyName"
                                    className={classNames(
                                      "form-control",
                                      "input-chpa",
                                      "icon-chpa",
                                      errors.companyName ? "is-invalid" : isApplyValidate ? "is-valid" : ""
                                    )}
                                    placeholder="&#xf0f7;"
                                    ref={register}
                                  />
                                  <small className="form-text small-chpa">Enter legal company name</small>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-12 col-12 form-group form-chpa-one">
                                <div className="form-chpa-two" onClick={handleClickHead}>
                                  <label htmlFor="locationOne">
                                    Location
                                    <span className="fa fa-asterisk span-chpa"></span>
                                  </label>

                                  <Controller
                                    control={control}
                                    name="locationHelpOne"
                                    defaultValue=""
                                    render={({ onChange }) => (
                                      <Typeahead
                                        id="locationHelpOne"
                                        options={options3}
                                        onInputChange={(text) => locationsContact(text || "")} 
                                        placeholder="&#xf041;"
                                        inputProps={{
                                          className: classNames(
                                            "form-control",
                                            "icon-chpa",
                                            "typecountry",
                                            "tt-query",
                                            errors.locationHelpOne ? "is-invalid" : isApplyValidate ? "is-valid" : ""
                                          ),
                                          spellCheck: "false",
                                        }}
                                        renderMenuItemChildren={(option, props) => (
                                          <span className="rbt-text">
                                            <Highlighter search={props.text}>{option}</Highlighter>
                                          </span>
                                        )}
                                        onChange={(value) => {
                                          onChange(value[0])
                                        }}
                                        onBlur={(e) => {
                                          onChange(e.target.value)
                                        }}
                                      />
                                    )}
                                  />
                                  <small id="locationHelpOne" className="form-text small-chpa">
                                    Enter a city, state, or country.
                                  </small>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-12 col-12 form-group form-chpa-one">
                                <div className="form-chpa-two">
                                  <label htmlFor="companyEmailAddress">
                                    Email Address
                                    <span className="fa fa-asterisk span-chpa"></span>
                                  </label>
                                  <input
                                    type="email"
                                    name="companyEmailAddress"
                                    className={classNames(
                                      "form-control",
                                      "input-chpa",
                                      "icon-chpa",
                                      errors.companyEmailAddress ? "is-invalid" : isApplyValidate ? "is-valid" : ""
                                    )}
                                    placeholder="&#xf2b6;"
                                    ref={register}
                                  />
                                  <small id="emailAddress" className="form-text small-chpa">
                                    Enter valid email address
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer-chpa">
            {isLoading ? (
              <div className="text-center" style={{ width: "156.21px", height: "39.06px" }}>
                <div className="spinner-border text-info" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <button type="submit" className="btn button-chpa">
                Get Routes Now!
              </button>
            )}
          </div>
        </div>
      </form>
      <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.KEY_RECAPTCHA} size="invisible" />
    </Modal>
  )
}

export default AdvanceSearch
