import { getApi, getApiLocations } from "../helpers/clientApi"

const getLocations = ({ cityName, token }) => {
  const url = `${process.env.URL_API_CHALUPA}/search/locations`
  const params = {
    cityName
  }

  return getApi(url, params, token)
}

const getLocationsHead = (token) => {
  const url = `${process.env.URL_API_CHALUPA}/search/locations`

  return getApiLocations(url, token)
}

export { getLocations, getLocationsHead }

