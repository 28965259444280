import { format, parse } from "date-fns"
import { shippingCompanies, listHaulage } from "../constants"

const formatDataShippingCompanies = ({ dataShippingCompanies, orderType, orderBy, haulageType, dataSearch, typeShippingCompany }) => {
  const rowShippingCompany = []
  const rowDaysString = []
  const rowDirect = []
  const rowVessel = []
  const rowVesselDepertureDate = []
  const rowOneTransshipment = []
  const rowTwoTransshipment = []
  const rowLoadingPorts = []
  const rowDischargePorts = []

  let data = []
  let filterShippingCompanies = shippingCompanies
  let filterShippingHaulage = listHaulage
  let haulageTypeSelect = haulageType
  let haulage = []
  const { haulageFound, shippingFound } = getHaulageShippingFound(dataShippingCompanies)


  const shippingCompany = shippingCompanies.filter((x) => x.name === typeShippingCompany)

  // Check if haulage and shipping company not found
  if (shippingFound.length === 0) {
    return null
  }

  if(shippingCompany && typeShippingCompany !== 'ALL') {
    filterShippingCompanies = shippingCompany
    haulage.push(...listHaulage[shippingCompany[0].id])
  }

  if (haulage.length > 0) {
    filterShippingHaulage = [];
    filterShippingHaulage[shippingCompany[0].id] = [];
  
    const filter = haulage.filter(x => x.name === haulageType);
  
    if (filter.length > 0) {
      filterShippingHaulage[shippingCompany[0].id].push(...filter);
    } else {
      filterShippingHaulage[shippingCompany[0].id].push(haulage[0]);
    }
  }
  
  for (const shippingCompany of filterShippingCompanies) {
    for(const haulageCompany of filterShippingHaulage[shippingCompany.id]) {
      shippingFound.map((x) => {
          if(x.name === shippingCompany.id && x.tipo === haulageCompany.name) {
            x.result.map((y) => {
              data.push({
                  shippingCompany: shippingCompany.name,
                  shippingCompanyURL: shippingCompany.URL,
                  days: parseInt(y.days),
                  daysString: y.daysString,
                  vessel: y.vessel,
                  vesselDepertureDate: parse(y.vesselDepartureDate, "MM-dd-yyyy", new Date()),
                  vesselDepertureDateString: format(parse(y.vesselDepartureDate, "MM-dd-yyyy", new Date()), "MMMM dd, yyyy"),
                  direct: y.direct,
                  oneTransshipment: y.oneTransshipment,
                  twoTransshipment: y.twoTransshipment,
                  loadingPort: y.loadingPort,
                  dischargePort: y.dischargePort,
              })
            })
          }
        })
    }
  }

  // Order
  data = getOrderFirstGrade(data, orderType, orderBy)
  data = getOrderSecondGrade(data, orderType)

  data.forEach((element) => {
    rowShippingCompany.push({ name: element.shippingCompany, url: element.shippingCompanyURL })
    rowDaysString.push(element.daysString)
    rowVessel.push(element.vessel)
    rowVesselDepertureDate.push(element.vesselDepertureDateString)
    rowDirect.push(element.direct)
    rowOneTransshipment.push(element.oneTransshipment)
    rowTwoTransshipment.push(element.twoTransshipment)
    rowLoadingPorts.push(element.loadingPort)
    rowDischargePorts.push(element.dischargePort)
  })

  return {
    data,
    filterShippingHaulage,
    haulageFound,
    haulageType: haulageTypeSelect,
    rowShippingCompany,
    rowDaysString,
    rowVessel,
    rowVesselDepertureDate,
    rowDirect,
    rowOneTransshipment,
    rowTwoTransshipment,
    rowLoadingPorts,
    rowDischargePorts,
  }
}

const getHaulageShippingFound = function (shippingCompanies) {
  const haulageFound = []
  const shippingFound = []


  const keys = Object.keys(shippingCompanies)

  for(const key of keys) {
    const shippingCompany = shippingCompanies[key]
    const haulageExtract = listHaulage[key]
    const nameSC = key
    const haulageFilter = []

    if(shippingCompany.length > 0) {
      haulageFound[key] = []

      for(const element of shippingCompany) {        
        for(const haulage of haulageExtract) {
          if(element.tipo === haulage.name && element.result.length > 0) {
            element.name = nameSC
            shippingFound.push(element) 
            haulageFilter.push(haulage)
          }
        }
      }

      haulageFound[key].push(...haulageFilter)
    }
  }

  return { shippingFound, haulageFound  }
}

const getOrderFirstGrade = function (data, orderType, orderBy) {
  let orderData = []

  if (orderType === "day") {
    if (orderBy === "desc") {
      orderData = data.sort((a, b) => a.days - b.days)
    } else {
      orderData = data.sort((a, b) => b.days - a.days)
    }
  } else if (orderType === "date") {
    if (orderBy === "desc") {
      orderData = data.sort((a, b) => a.vesselDepertureDate - b.vesselDepertureDate)
    } else {
      orderData = data.sort((a, b) => b.vesselDepertureDate - a.vesselDepertureDate)
    }
  } else if (orderType === "loadingPort") {
    if (orderBy === "desc") {
      orderData = data.sort((a, b) => a.loadingPort - b.loadingPort)
    } else {
      orderData = data.sort((a, b) => b.loadingPort - a.loadingPort)
    }
  } else if (orderType === "dischargePort") {
    if (orderBy === "desc") {
      orderData = data.sort((a, b) => {
        const wordA = a.dischargePort.toUpperCase()
        const wordB = b.dischargePort.toUpperCase()

        return wordA < wordB ? -1 : wordA > wordB ? 1 : 0
      })
    } else {
      orderData = data.sort((a, b) => {
        const wordA = a.dischargePort.toUpperCase()
        const wordB = b.dischargePort.toUpperCase()

        return wordA > wordB ? -1 : wordA < wordB ? 1 : 0
      })
    }
  }

  return orderData
}

const getOrderSecondGrade = function (data, orderType) {
  let orderData = []
  let uniqueData = []

  if (orderType === "day") {
    uniqueData = [...new Set(data.map((item) => item.days))]

    for (const unique of uniqueData) {
      orderData.push(...data.filter((item) => item.days === unique).sort((a, b) => a.vesselDepertureDate - b.vesselDepertureDate))
    }
  } else if (orderType === "date") {
    uniqueData = [...new Set(data.map((item) => item.vesselDepertureDateString))]
    for (const unique of uniqueData) {
      orderData.push(...data.filter((item) => item.vesselDepertureDateString === unique).sort((a, b) => a.days - b.days))
    }
  } else if (orderType === "loadingPort") {
    uniqueData = [...new Set(data.map((item) => item.loadingPort))]
    for (const unique of uniqueData) {
      orderData.push(...data.filter((item) => item.loadingPort === unique).sort((a, b) => a.days - b.days))
    }
  } else if (orderType === "dischargePort") {
    uniqueData = [...new Set(data.map((item) => item.dischargePort))]
    for (const unique of uniqueData) {
      orderData.push(...data.filter((item) => item.dischargePort === unique).sort((a, b) => a.days - b.days))
    }
  }

  return orderData
}

export { formatDataShippingCompanies }
